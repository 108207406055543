<template>
    <div>
        <h1>{{ $t('Přihlásit') }}</h1>
        <div class="row justify-content-center pt-3">
            <div class="col-6">
                <div class="card text-dark bg-light">
                    <div class="card-body">
                        <b-form>
                            <b-form-invalid-feedback
                                :force-show="fieldIsInvalid('message')">
                                {{ login_form_errors.message }}
                            </b-form-invalid-feedback>
                            <b-form-group
                                :label="$t('Uživatelské jméno')"
                                label-for="uid"
                                label-align="left"
                                >
                                    <b-form-input
                                        id="uid"
                                        v-model="login_form.uid">
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        :force-show="fieldIsInvalid('username')">
                                        {{ login_form_errors.username }}
                                    </b-form-invalid-feedback>
                            </b-form-group>
                            <b-form-group
                                :label="$t('Heslo')"
                                label-for="password"
                                label-align="left"
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="login_form.password">
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        :force-show="fieldIsInvalid('password')">
                                        {{ login_form_errors.password }}
                                    </b-form-invalid-feedback>
                            </b-form-group>
                    
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="login"
                            >{{ $t('Přihlásit') }}</b-button>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { state, mutations } from '@/store'
//import { createNotice } from '@/lib/gms/error.js'

export default {
    components: { },

    computed: {
        login_form( ) {
            return state.auth.login_form
        },

        login_form_errors( ) {
            return state.auth.login_form_errors
        }
    },

    created( ) {
        mutations.readSetting( )
    },

    data( ) {
        return { }
    },

    methods: {
        fieldIsInvalid(field) {
            //console.log(state.login_form_errors[field])
            return state.auth.login_form_errors[field] != null
        },

        login( ) {
            mutations.login(
                this.login_form.uid,
                this.login_form.password)
                .then(/*response*/ ( ) => {
                    //createNotice(this.$t("Byl jste úspěšně přihlášen."))

                    this.$router.push({ name: "default-view", params: {  } })
                })
                .catch(errors => {
                    mutations.processAuthLoginErrors(errors)
                })
        }
    },

    mounted( ) { }
}
</script>